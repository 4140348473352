import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { positionsActions, devicesActions, sessionActions, revendasActions, geofencesActions,
  groupsActions, driversActions, maintenancesActions, plansActions, billingsActions } from './store';
import { useEffectAsync } from './reactHelper';
import { useTranslation } from './LocalizationProvider';
import { prefixString } from './common/stringUtils';

import Swal from 'sweetalert2';


const SocketController = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const t = useTranslation();

  const authenticated = useSelector((state) => !!state.session.user);
  const devices = useSelector((state) => state.devices.items);

  const socketRef = useRef();

  const [events, setEvents] = useState([]);
  const [notifications, setNotifications] = useState([]);


  const connectSocket = () => {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const socket = new WebSocket(`${protocol}//${window.location.host}/api/socket`);
    socketRef.current = socket;

    window.setTimeout(function(){
          
      socket.close();
      
      if(localStorage.getItem('loginEmail')){        
      connectSocket();
      }    
  
    }, 30000)


    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.devices) {
        dispatch(devicesActions.update(data.devices));
      }
      if (data.positions) {
        dispatch(positionsActions.update(data.positions));
      }
      if (data.events) {
        setEvents(data.events);
      }
    };
  };

  useEffectAsync(async () => {
    const response = await fetch('/api/server');
    if (response.ok) {
      dispatch(sessionActions.updateServer(await response.json()));
    }
  }, []);

  useEffectAsync(async () => {
    if (authenticated) {
      const response = await fetch('/api/devices');
      if (response.ok) {
        dispatch(devicesActions.refresh(await response.json()));
      }
      connectSocket();
      return () => {
        const socket = socketRef.current;
        if (socket) {
          socket.close();
        }
      };
    }


    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")   

    if(token){

      window.history.pushState(null, null, window.location.pathname);      

      const response = await fetch(`${localStorage.getItem('shared')}?action=token&token=`+token+``);
      const data = await response.json();
      
      if(data.response == 'sucess'){
      window.open("/shared/index.html?token="+token+"", "_self");
      }

      else{

        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Link de compartilhamento expirado ou inválido',  
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          timer: 5000,
          timerProgressBar: true
        })

      }

    }


     history.push('/login'); 

   
    
    return null;
  }, [authenticated]);

  useEffectAsync(async () => {
    if (!authenticated) {
      const socket = socketRef.current;
        if (socket) {
          dispatch(devicesActions.refresh([]));
          dispatch(geofencesActions.refresh([]));
          dispatch(groupsActions.refresh([]));
          dispatch(driversActions.refresh([]));
          dispatch(maintenancesActions.refresh([]));
          dispatch(plansActions.refresh([]));
          dispatch(billingsActions.refresh([]));
          socket.close();
        }
    }
    return null;
  }, [authenticated]);

  useEffect(() => {
    setNotifications(events.map((event) => ({
      id: event.id,
      message: `${devices[event.deviceId]?.name}: ${t(prefixString('event', event.type))}`,
      show: true,
    })));
  }, [events, devices]);

  

  /** MOD */

  useEffectAsync(async () => {
    const response = await fetch('/api/revendas');
    if (response.ok) {
      const revendas = await response.json();
      dispatch(revendasActions.update(revendas));

      var domain = window.location.hostname;
      var index = revendas.findIndex(r => r.url.includes(domain) === true);
      dispatch(revendasActions.select(index > -1 ? revendas[index] : revendas[0]));
    }
  }, [authenticated]);


  return (
    <>
      {notifications.map((notification) => (
        <Snackbar
          key={notification.id}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={notification.show}
          message={notification.message}
          autoHideDuration={5000}
          onClose={() => setEvents(events.filter((e) => e.id !== notification.id))}
        />
      ))}
    </>
  );
};

export default connect()(SocketController);
